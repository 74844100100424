.layout-container {
  min-height: 100vh;
}

.logo-bar {
  margin: 32px 0;
}

.content {
  padding: 32px;
}
