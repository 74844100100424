.form-login {
  height: 100vh;
  background-color: #edf2f7;
}

.title {
  margin: 0;
}

.login-button {
  width: 100%;
}

.align-right {
  text-align: right;
}
